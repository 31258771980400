import { IDeliverySearchRecord, IFilterInputs, IUserEmailProfile, IUserProfile } from '../models';
import { ai } from '../components/ApplicationInsightsProvider/ApplicationInsightsService';
import { trackLearnUrlRedirect } from '../components/ApplicationInsightsProvider/LearnRedirectTelemetryService';
import { IGetEventSessionsRequest } from '../models/EventManagement/EventSessions';
export class Helper {
  static learnUrlPatterns = process.env.REACT_APP_LEARN_URL_PATTERNS?.split(',').map(pattern => new RegExp(pattern)) || [];

  /**
   *  return the locale.
   *
   * @returns {string}
   */
  public static getLocale(): string {
    let locale = localStorage.getItem('locale');
    if (locale === '' || locale === null) {
      //default value
      locale = 'en-us';
    } else {
      locale = locale.toLowerCase();
    }
    return locale;
  }

  public static getUtcToLocalConvertedDate(flexTimeZone: string, flexDate: string) {
    const date = new Date(flexDate);
    const getTimeZone = flexTimeZone?.substring(4, 10)?.split(':');
    let offset = getTimeZone[0];
    if (getTimeZone[1] !== '00') {
      const getMinutes = parseInt(getTimeZone[1]) / 60;
      const getMinutesSplit = getMinutes.toString().split('.');
      offset = getTimeZone[0] + '.' + getMinutesSplit[1];
    }
    const itemDisplayDate = new Date(date.getTime() + 3600000 * parseFloat(offset));
    return itemDisplayDate;
  }

  public static supportLinkVisitLog(pageName: string, emailAddress?: string, learnerId?: string) {
    const date = new Date();
    if (ai.appInsights) {
      ai.appInsights.trackTrace({
        message: 'Action: Support link has been vistied on this page-' + pageName,
        properties: {
          emailAddress: emailAddress,
          learnerId: learnerId,
          dateOfVisit: date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear(),
          timeOfVisit: date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
        }
      });
    }
  }

  public static GetUrlWithRole(cardDetails: any, profileRole: string): string {
    if (cardDetails.cardtitleid?.toLowerCase() === 'productivity workshops') {
      return cardDetails.cardlink;
    }

    const urlToParse = cardDetails.cardlink;
    profileRole = profileRole?.toLowerCase().replace(' ', '-');

    const url: URL = new URL(urlToParse);
    const queryStringParts: string[] = decodeURIComponent(url.search).split('&');
    const ignoreRoles: string[] = ['fundamental-skills', 'technology-educator'];
    const roles: string[] = queryStringParts[0].replace('?roles=', '').split(',');
    const mappedRoleIndex = roles.indexOf(profileRole);
    let filteredQueryString = '';
    let roleFilteredUrl = '';

    if (ignoreRoles.indexOf(profileRole) > -1) {
      filteredQueryString = encodeURI(`&${queryStringParts.slice(1).join('&')}`);
      roleFilteredUrl = `${url.protocol}//${url.hostname}${url.pathname}?${filteredQueryString}`;
      return roleFilteredUrl;
    }

    if (mappedRoleIndex > -1) {
      filteredQueryString = encodeURI(`roles=${roles[mappedRoleIndex]}&${queryStringParts.slice(1).join('&')}`);
      roleFilteredUrl = `${url.protocol}//${url.hostname}${url.pathname}?${filteredQueryString}`;
      return roleFilteredUrl;
    }
    return urlToParse;
  }

  public static setLocalStatusForDelivery = (deliveryId: string) => {
    if (deliveryId) {
      let deliveriesInprogress = [];
      const deliveryList = localStorage.getItem('deliveriesInprogress');

      if (deliveryList) {
        deliveriesInprogress = JSON.parse(deliveryList);
      }
      const deliverIdInProgress = { deliveryId: deliveryId, expiry: new Date().getTime() + 300000 };
      deliveriesInprogress.filter((deliverObj: { deliveryId: string; expiry: Date }) => deliverObj.deliveryId === deliveryId);
      deliveriesInprogress.push(deliverIdInProgress);
      localStorage.setItem('deliveriesInprogress', JSON.stringify(deliveriesInprogress));
    }
  };

  public static getLocalStatusFordelivery = (deliveryId: string) => {
    if (deliveryId) {
      const deliveriesList = localStorage.getItem('deliveriesInprogress');
      if (deliveriesList) {
        const deliveriesInProgress = JSON.parse(deliveriesList);

        const delivery = deliveriesInProgress.find(
          (deliverObj: { deliveryId: string; expiry: Date }) => deliverObj.deliveryId === deliveryId
        );

        if (!delivery) {
          return false;
        }
        if (delivery.expiry < Date.now()) {
          const filteredDelivery = deliveriesInProgress.filter(
            (deliverObj: { deliveryId: string; expiry: Date }) => deliverObj.deliveryId !== deliveryId
          );
          localStorage.setItem('deliveriesInprogress', JSON.stringify(filteredDelivery));
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
  };

  public static setClarityCustomTag = (key: string, value: string) => {
    if (typeof window !== undefined) {
      const wnd = window as any;
      wnd['clarity']('set', key, value);
    }
  };

  public static handleLearnRedirect = (disabled: boolean, url: string | undefined, openTarget?: string, openFeatures?: string) => {
    if (disabled || url === undefined || !Helper.isLearnURL(url)) {
      window.open(url, openTarget, openFeatures);
      return;
    }
    const ssoURL = Helper.appendLearnSSO(url);
    Helper.setClarityCustomTag('LearnRedirect', ssoURL ?? '');
    Helper.setClarityCustomTag('EventLearnRedirect', 'LearnRedirected');
    trackLearnUrlRedirect(ssoURL);
    return window.open(ssoURL, openTarget, openFeatures);
  };

  /**
   * returns mouse event handler
   *
   * @returns {(e: React.MouseEvent | MouseEvent) => void}
   * @param disabled
   * @param url
   * @param openTarget
   * @param openFeatures
   */
  public static handleLearnRedirectEvent = (disabled: boolean, url: string, openTarget?: string, openFeatures?: string) => (
    e: React.MouseEvent | MouseEvent
  ) => {
    e.preventDefault();
    Helper.handleLearnRedirect(disabled, url, openTarget, openFeatures);
  };

  public static getDomain = (workEmail: string | null): string | undefined | null => {
    return workEmail !== null ? workEmail.split('@').pop() : null;
  };

  private static isLearnURL = (url: string): boolean => {
    for (const pattern of Helper.learnUrlPatterns) {
      if (pattern.test(url)) {
        return true;
      }
    }
    return false;
  };

  private static appendLearnSSO = (url: string): string => {
    const urlBuilder = new URL(url);
    if (urlBuilder.host.includes('safelinks.protection.outlook.com') || !urlBuilder.protocol.includes('https')) {
      return url;
    }
    urlBuilder.searchParams.append('sso', 'esi');
    return urlBuilder.toString();
  };

  public static getCurrentWeekForBL(eventStartDate: Date, eventEndDate: Date, currentDateTimeNow: Date) {
    let currentWeekNumber = 0;

    if (currentDateTimeNow < eventStartDate) {
      currentWeekNumber = 0;
    } else if (currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 6)) {
      currentWeekNumber = 1;
    } else if (
      currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 6) &&
      currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 13)
    ) {
      currentWeekNumber = 2;
    } else if (
      currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 13) &&
      currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 20)
    ) {
      currentWeekNumber = 3;
    } else if (currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 20) && currentDateTimeNow < eventEndDate) {
      currentWeekNumber = 4;
    } else if (currentDateTimeNow >= eventEndDate) {
      currentWeekNumber = 5;
    }
    return currentWeekNumber;
  }

  public static addDaysToDate(date: Date, days: number) {
    const updatedDate = new Date(date);
    updatedDate.setDate(date.getDate() + days);
    return updatedDate;
  }

  public static getProfileOrgId(profile: IUserProfile) {
    return 'TPID' in profile ? profile.TPID : 'skillingId' in profile ? profile.skillingId : '';
  }

  public static getEmailProfileOrgId(profile: IUserEmailProfile) {
    return 'tpid' in profile ? profile.tpid : 'skillingId' in profile ? profile.skillingId : '';
  }

  public static getFilterOrgId(filter: IFilterInputs) {
    return 'tpId' in filter ? filter.tpId : 'skillingId' in filter ? filter.skillingId : '';
  }

  public static hasProfileContainTpid(profile: IUserProfile) {
    if ('TPID' in profile) {
      return true;
    } else if ('skillingId' in profile) {
      return false;
    }
    return null;
  }

  public static hasFilterContainTpid(filter: IFilterInputs) {
    if ('tpId' in filter) {
      return true;
    } else if ('skillingId' in filter) {
      return false;
    }
    return null;
  }

  public static hasDelSearchContainTpid(delSearch: IDeliverySearchRecord) {
    if ('tpId' in delSearch) {
      return true;
    } else if ('skillingId' in delSearch) {
      return false;
    }
    return null;
  }

  public static hasEventSessionContainTpid(eventSession: IGetEventSessionsRequest) {
    if ('tpId' in eventSession) {
      return true;
    } else if ('skillingId' in eventSession) {
      return false;
    }
    return null;
  }

  public static returnOrgObject(profile: IUserProfile) {
    let orgObj = {};
    const prof = profile;
    if (this.hasProfileContainTpid(profile)) {
      orgObj = {
        tpId: 'TPID' in prof ? prof.TPID : ''
      };
    } else if (!Helper.hasProfileContainTpid(profile)) {
      orgObj = {
        skillingId: 'skillingId' in prof ? prof.skillingId : ''
      };
    }
    return orgObj;
  }

  public static returnFilterOrgObject(filterInput: IFilterInputs) {
    let orgObj = {};
    const filter = filterInput;
    if (this.hasFilterContainTpid(filterInput)) {
      orgObj = {
        tpId: 'tpId' in filter ? filter.tpId : ''
      };
    } else if (!Helper.hasFilterContainTpid(filterInput)) {
      orgObj = {
        skillingId: 'skillingId' in filter ? filter.skillingId : ''
      };
    }
    return orgObj;
  }

  public static returnDelSearchOrgObject(delSearchInput: IDeliverySearchRecord) {
    let orgObj = {};
    const delSearch = delSearchInput;
    if (this.hasDelSearchContainTpid(delSearchInput)) {
      orgObj = {
        tpId: 'TPID' in delSearch ? delSearch.TPID : ''
      };
    } else if (!Helper.hasDelSearchContainTpid(delSearchInput)) {
      orgObj = {
        skillingId: 'skillingId' in delSearch ? delSearch.skillingId : ''
      };
    }
    return orgObj;
  }

  public static returnEventSessionOrgObject(eventSessionInput: IGetEventSessionsRequest) {
    let orgObj = {};
    const eventSession = eventSessionInput;
    if (this.hasEventSessionContainTpid(eventSessionInput)) {
      orgObj = {
        tpId: 'tpId' in eventSession ? eventSession.tpId : ''
      };
    } else if (!Helper.hasEventSessionContainTpid(eventSessionInput)) {
      orgObj = {
        skillingId: 'skillingId' in eventSession ? eventSession.skillingId : ''
      };
    }
    return orgObj;
  }
}
