import { AxiosRequestConfig } from 'axios';

import {
  IAccountUnlinkRequest,
  ICertification,
  ICreateProfileRequest,
  ILearningPath,
  ILinkedAccount,
  IRequestResponse,
  IUnlinkedAccountResult,
  IUpdateProfileRequest,
  IUserProfile,
  IEmailVerificationRequest,
  IUserEmailProfile,
  ILearningPathCard,
  IEmailValidationRequest,
  IVerificationCodeRequest,
  ILanguage,
  ITimezone,
  IModality,
  IRole,
  IFilterInputs,
  IMSClassesCatalog,
  ILearningPartners,
  IDeliveryInput,
  IDeliveryDesc,
  IEventRegistrationRequest,
  IEventRegistrationResponse,
  ICancelRegistrationRequest,
  ICancelRegistrationResponse,
  ICountry,
  ICertificationRequest,
  IWorkEmailVerificationCodeRequest,
  IFilterInputMyDeliveries,
  IUpdateEmailVerificationRequest,
  INotifications,
  IESIAgreement,
  ICancelWaitlistRegistrationResponse,
  ICancelWaitlistRegistrationRequest,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse,
  ITokensAvailable,
  ILearnerIdRequest,
  ILearningPathGroups,
  IUserSSOEligibility,
  IVerifyCleanupUserRequest,
  IUnlinkEmailAddressErrorRequest,
  ICopilotTokenRequest,
  ICopilotTokenResponse,
  IVerificationEmailRequest
} from '../../models';
import { IUpdateAccountRequest, IUpdateAccountRequestResponse } from '../../models/UpdateAccountRequest';
import { IMeasureUpLTISettings } from '../../models/MeasureUpLTISettings';
import { applicationConfig } from '../../Settings';
import { EndpointConfiguration, EndpointFunction, EndpointFunctionParameterless } from '../endpoint';
import { default as BaseApi, RequestMethod } from './BaseApi';
import { IFeatureFlag } from '../../models/FeatureFlag';
import { IRequestAdditionalTokensRequest, IRequestAdditionalTokensResponse } from '../../models/RequestAdditionalTokens';
import { IMultipleProfileRequest, IMultipleProfileResponse } from '../../models/multipleProfileRequest';
import { IDeleteDuplicateProfileRequest, IDeleteDuplicateProfileResponse } from '../../models/deleteDuplicateProfileRequest';
import {
  IGetEventSessionsRequest,
  IGetEventSessionsResponse,
  IPatchEventSessionsRequest,
  IPatchEventSessionsResponse
} from '../../models/EventManagement/EventSessions';
import { IPostResendInviteRequest, IPostResendInviteResponse } from '../../models/EventManagement/PostResendInvite';
import { IOfferTransactionHistory } from '../../models/IOfferTransactionHistory';
import { Helper } from '../../utilities';

/**
 * A class containing static methods to request resources from the APIs
 */
export default class Api extends BaseApi {
  /**
   * Fetch the user profile if it exists
   *
   * @param workEmailAddress - Work email address passed in the header
   */
  public static async GET_PROFILE(workEmailAddress: string): Promise<IRequestResponse<IUserProfile>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.profile as string;
    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET,
      headers: { 'Esi-Customer-Email': workEmailAddress === undefined || null ? '' : workEmailAddress }
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Get Feature flag value and Alert message
   */

  public static async GET_FEATURE_FLAG(): Promise<IRequestResponse<IFeatureFlag[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.readFeatureFlag as string;
    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig, true);
  }

  /**
   * Fetch the user is part of support security or distributor group.
   *
   */
  public static async GET_USER_PARTOF(): Promise<IRequestResponse<boolean>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.checkUserPartOf as string;
    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Create or update the user profile
   *
   * @param params - Parameters
   */
  public static async CREATE_PROFILE(params: ICreateProfileRequest): Promise<IRequestResponse<IUserProfile>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.profile as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Create or update the user profile
   *
   * @param params - Parameters
   */
  public static async UPDATE_PROFILE(params: IUpdateProfileRequest): Promise<IRequestResponse<IUserProfile>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.profile as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.PUT,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * delete the linked user email Address
   *
   * @param params - Parameters
   */
  public static async DELETE_ACCOUNT(): Promise<IRequestResponse<IUserProfile>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.deleteAccount as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch the Learning Paths based on an Id
   *
   * @param learningPlanId Learning plan Id
   */
  public static async GET_LEARNING_PATH(learningPlanId: string): Promise<IRequestResponse<ILearningPath>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.learningPlan as EndpointFunction;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint(learningPlanId) });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch the Learning Paths based on an Id
   *
   * @param learningPlanId Learning plan Id
   */
  public static async GET_DEFAULT_LEARNING_PATH(learningPlanId: string): Promise<IRequestResponse<ILearningPathCard[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.learningPlanCard as EndpointFunction;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint(learningPlanId) });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch the Learning Partners based on an orgId
   *
   * @param orgId Top Parent company Id
   */
  public static async GET_DEFAULT_LEARNING_PARTNER(orgId: string): Promise<IRequestResponse<ILearningPartners[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.learningPartner as EndpointFunction;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint(orgId) });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch the certifications
   *
   * @function
   * @param certificationRequest
   * @param enrollmentPlanId - Enrollment plan Id
   */
  public static async GET_CERTIFICATIONS(certificationRequest: ICertificationRequest): Promise<IRequestResponse<ICertification[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.certification as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: certificationRequest
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch the token for copliot
   *
   * @function
   * @param copilotTokenRequest
   */
  public static async GET_COPILOTTOKENS(copilotTokenRequest: ICopilotTokenRequest): Promise<IRequestResponse<ICopilotTokenResponse>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.copilotTokensRequest as EndpointFunction;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint(encodeURIComponent(copilotTokenRequest.userId)) });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Get and array of accounts linked to the current user's account
   *
   * @param workEmailAddress
   */
  public static async GET_LINKED_ACCOUNTS(workEmailAddress = ''): Promise<IRequestResponse<ILinkedAccount[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.accountLinking as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET,
      headers: { 'Esi-Customer-Email': workEmailAddress }
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Link multiple accounts together by creating an association between two linking objects
   *
   * @param requestId - Request Id
   */
  public static async LINK_ACCOUNTS(requestId: string): Promise<any> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.accountLinking as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: JSON.stringify({ emailAddress: requestId })
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Unlink two accounts from each other
   *
   * @param unlinkables - Unlinking request
   */
  public static async UNLINK_ACCOUNTS(unlinkables: IAccountUnlinkRequest): Promise<IRequestResponse<IUnlinkedAccountResult>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.accountLinking as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.PUT,
      data: unlinkables
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Send the verification email with access code.
   *
   * @param params - Parameters
   */
  public static async EMAIL_VERIFICATION(params: IEmailVerificationRequest): Promise<IRequestResponse<any>> {
    const service = applicationConfig.services.accessCode;
    const endpoint = service.endpoints.sendAccessCode as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig, true);
  }

  /**
   * Send the workemail address for update and verification.
   *
   * @param params - Parameters
   */
  public static async UPDATE_WORKEMAILADDRESS(params: IWorkEmailVerificationCodeRequest): Promise<IRequestResponse<any>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.updateWorkEmailAddress as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Send the workemail address for update and verification.
   *
   * @param params - Parameters
   */
  public static async VERIFY_WORKEMAILADDRESS(params: IUpdateEmailVerificationRequest): Promise<IRequestResponse<any>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.verifyWorkEmailAddress as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Send the verification email with access code.
   *
   * @param params - Parameters
   */
  public static async VERIFIY_CODE(params: IVerificationCodeRequest): Promise<IRequestResponse<any>> {
    const service = applicationConfig.services.accessCode;
    const endpoint = service.endpoints.verifyAccessCode as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET,
      params: {
        email: params.email,
        accessCode: params.accessCode,
        applicationName: params.applicationName
      }
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig, true);
  }

  /**
   * Check the Email Verification Service Status
   */
  public static async EMAIL_SERVICE_STATUS(): Promise<IRequestResponse<boolean>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.emailServiceStatus as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig, true);
  }

  /**
   * Create or update the user profile
   *
   * @param params - Parameters
   */
  public static async EMAIL_VALIDATION(params: IEmailValidationRequest): Promise<IRequestResponse<IUserEmailProfile>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.emailValidationClaims as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig, true);
  }

  /**
   * Fetch the languages
   */
  public static async GET_LANGUAGES(): Promise<IRequestResponse<ILanguage[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.languages as EndpointFunctionParameterless;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint() });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch the notifications
   *
   * @param pageName
   */
  public static async GET_NOTIFICATIONS(pageName: string): Promise<IRequestResponse<INotifications[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.notifications as EndpointFunction;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint(pageName) });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Fetch the esiAgreement
   *
   * @param locale
   * @param local
   */
  public static async GET_ESIAgreement(locale: string): Promise<IRequestResponse<IESIAgreement>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.esiAgreement as EndpointFunction;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint(locale) });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
  /**
   * Fetch the timezones
   */
  public static async GET_TIMEZONES(): Promise<IRequestResponse<ITimezone[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.timezones as EndpointFunctionParameterless;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint() });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
  /**
   * Fetch the modalities
   */
  public static async GET_MODALITIES(): Promise<IRequestResponse<IModality[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.modalities as EndpointFunctionParameterless;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint() });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Fetch the roles
   */
  public static async GET_ROLES(): Promise<IRequestResponse<IRole[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.roles as EndpointFunctionParameterless;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint() });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch the MeasureUp LTI Settings
   */
  public static async GET_MEASUREUP_LTI_SETTINGS(): Promise<IRequestResponse<IMeasureUpLTISettings[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.measureUpLTISettings as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Post Filter Inputs
   *
   * @param params - Parameters
   */
  public static async MSCATALOG(params: IFilterInputs): Promise<IRequestResponse<IMSClassesCatalog>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.mscatalog as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Post DeliveryDesc Inputs
   *
   * @param params - Parameters
   */
  public static async DELIVERYDESC(params: IDeliveryInput): Promise<IRequestResponse<IDeliveryDesc>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.deliverydetails as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Event Registration
   *
   * @param params - Parameters
   */
  public static async CREATE_EVENT_REGISTRATION(params: IEventRegistrationRequest): Promise<IRequestResponse<IEventRegistrationResponse>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.eventRegistration as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Post cancel registrations
   *
   * @param params - Parameters
   */
  public static async CANCEL_REGISTRATION(params: ICancelRegistrationRequest): Promise<IRequestResponse<ICancelRegistrationResponse>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.cancelRegistration as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Post cancel Waitlist
   *
   * @param params - Parameters
   */
  public static async CANCEL_WAITLIST(
    params: ICancelWaitlistRegistrationRequest
  ): Promise<IRequestResponse<ICancelWaitlistRegistrationResponse>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.cancelWaitlist as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Post confirm registrations
   *
   * @param params - Parameters
   */
  public static async CONFIRM_REGISTRATION(params: IConfirmRegistrationRequest): Promise<IRequestResponse<IConfirmRegistrationResponse>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.confirmRegistration as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   * Fetch the Country
   */
  public static async GET_COUNTRY(): Promise<IRequestResponse<ICountry[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.country as EndpointFunctionParameterless;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint() });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * get registered and waitlisted course for a learner ID
   *
   * @param params
   */
  public static async GET_MyDeliveries(params: IFilterInputMyDeliveries): Promise<IRequestResponse<IMSClassesCatalog>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.myDeliveries as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * get DELIVERY STATUS on search tile for a learner ID
   *
   * @param params
   */
  public static async GET_SearchDeliveriesStatus(params: IFilterInputMyDeliveries): Promise<IRequestResponse<IMSClassesCatalog>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.searchDeliveriesStatus as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /*
   * Check the CODE_VERIFICATION_STATUS
   * @param params
   */
  public static async EMAILCODE_VERIFICATION_STATUS(params: IVerificationEmailRequest): Promise<IRequestResponse<boolean>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.emailCodeVerificationStatus as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig, true);
  }

  /**
   * workEmail updated, no change to OID + emailAddress
   *
   * @param params
   */
  public static async UPDATE_ACCOUNT_WorkEmailOnly(
    params: IUpdateAccountRequest[]
  ): Promise<IRequestResponse<IUpdateAccountRequestResponse[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.updateAccountWorkEmailOnly as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   *workEmail updated, OID + emailAddress nullified
   *
   * @param params
   */
  public static async UPDATE_ACCOUNT(params: IUpdateAccountRequest[]): Promise<IRequestResponse<IUpdateAccountRequestResponse[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.updateAccount as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   *Duplicate profiles deletions
   *
   * @param params
   */
  public static async DELETE_DUPLICATE_PROFILE(
    params: IDeleteDuplicateProfileRequest[]
  ): Promise<IRequestResponse<IDeleteDuplicateProfileResponse[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.deleteDuplicateProfile as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }
  /**
   *Multiple profiles search
   *
   * @param params
   */
  public static async GET_MULTIPLE_PROFILES(params: IMultipleProfileRequest[]): Promise<IRequestResponse<IMultipleProfileResponse[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.multipleProfiles as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   *No change to workEmail, OID + emailAddress nullified
   *
   * @param params
   */
  public static async UPDATE_ACCOUNT_OID_EmailAddressOnly(
    params: IUpdateAccountRequest[]
  ): Promise<IRequestResponse<IUpdateAccountRequestResponse[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.updateAccountOIDEmailAddressOnly as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * get number of available tokens for a learner ID
   *
   * @param params
   */
  public static async TOKENS_AVAILABLE(params: ILearnerIdRequest): Promise<IRequestResponse<ITokensAvailable>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.tokensAvailable as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * get learning path details data
   *
   * @param params
   */
  public static async GET_LEARNING_PATH_GROUPS(params: string): Promise<IRequestResponse<ILearningPathGroups[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.learningPathGroups as EndpointFunctionParameterless;

    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint() });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Sending request for Additonal Tokens
   *
   * @param params
   */
  public static async SEND_REQUEST_AdditionalTokens(
    params: IRequestAdditionalTokensRequest[]
  ): Promise<IRequestResponse<IRequestAdditionalTokensResponse[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.sendRequestForAdditonalTokens as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Gets event sessions for the given event ID
   *
   * @param params
   */
  public static async GET_EventSessions(params: IGetEventSessionsRequest): Promise<IRequestResponse<IGetEventSessionsResponse>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.eventSessions as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET,
      params: {
        deliveryId: params.deliveryId,
        learnerId: params.learnerId,
        ...Helper.returnEventSessionOrgObject(params),
        cacheBuster: Date.now()
      }
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Requests to send/cancel session invites
   *
   * @param params
   */
  public static async PATCH_EventSessions(params: IPatchEventSessionsRequest[]): Promise<IRequestResponse<IPatchEventSessionsResponse>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.eventSessions as string;

    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.PATCH,
      data: params
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Requests to resend invites
   *
   * @param params
   */
  public static async POST_ResendInvite(params: IPostResendInviteRequest): Promise<IRequestResponse<IPostResendInviteResponse>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.resendInvite as string;
    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch if the domain is enabled for SSO
   *
   * @param domain - Work domain address passed in the request body
   */
  public static async GET_SSO_STATUS(domain: string): Promise<IRequestResponse<IUserSSOEligibility>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.ssoStatus as EndpointFunction;
    const endpointConfig = new EndpointConfiguration({ service, endpoint: endpoint(encodeURIComponent(domain)) });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /** Requests to verify and profile clenaup of users
   *
   * @param params
   */
  public static async VERIFY_CLEANUP_USERS(params: IVerifyCleanupUserRequest): Promise<IRequestResponse<any>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.verifyCleanupUser as string;
    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Requests to unlink the users link account
   *
   * @param params
   */
  public static async Unlink_EmailAddress_Error(params: IUnlinkEmailAddressErrorRequest): Promise<IRequestResponse<any>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.unlinkEmailAddressError as string;
    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.POST,
      data: params
    };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch the linked accounts for a given email
   */
  public static async GET_SSO_LINKED_ACCOUNTS(): Promise<IRequestResponse<IUserProfile[]>> {
    const service = applicationConfig.services.enrollmentServices;
    const endpoint = service.endpoints.ssoLinkedAccounts as string;
    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = { method: RequestMethod.GET };
    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig);
  }

  /**
   * Fetch Offer Transaction History.
   *
   * @param documentId
   * @param params - Parameters
   */
  public static async Get_OfferTransactionHistory(documentId: string): Promise<IRequestResponse<IOfferTransactionHistory>> {
    const service = applicationConfig.services.offerDiscount;
    const endpoint = service.endpoints.offerTransactionHistory as string;
    const endpointConfig = new EndpointConfiguration({ service, endpoint });
    const requestConfig: AxiosRequestConfig = {
      method: RequestMethod.GET,
      params: {
        docsId: documentId
      }
    };

    return super.REQUEST_WITH_CONFIG(endpointConfig, requestConfig, true);
  }
}
